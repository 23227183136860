html {
  font-size: 10px;
}

body {
  font: 14px "Century Gothic", Futura, sans-serif;
  overflow-x: hidden;
}


/*******************************************
  QuantityChanger
*******************************************/

.quantityChanger {
  width: 130px;
}

.quantityChanger .quantityButton {
  width: 20px;
  display: inline;
}

.quantityChanger .quantityInput {
  width: 40px;
  display: inline;
}

.quantityChanger .quantityInput input {
  width: 40px;
}

.header .language.dropdown .main {
  background-color: #FFFFFF;
  font-family: Ubuntu, "Medium";
  border-color: #FFFFFF;
  color: #15113b;
  font-size: 1.8rem;
  font-weight: bold;
}

.language.dropdown {
  text-align: center;
  color: #15113b;
  font-family: Ubuntu, "Medium";
  font-size: 1.8rem;
  font-weight: bold;
  margin-top: 12px;
}

.dropdown-menu {
  font-size: 1.6rem;
  padding: 0.8rem 0;
  border-radius: 0.4rem;
}

.header .currency.dropdown .btn-primary.focus, .btn-primary:focus, .header .language.dropdown .btn-primary.focus, .btn-primary:focus {
  box-shadow: none !important;
}

.header .language.dropdown .menu .item.selected {
  color: #00AEEF;
}

/*******************************************
  Cart
*******************************************/

.cart {
  width: 350px;
}

.cart .total {
  font-weight: bold;
  padding-top: 10px;
  padding-bottom: 10px;
}

.article {
  padding-top: 10px;
  padding-bottom: 10px;
}

.article:not(:last-of-type) {
  border-bottom: 1px solid;
}

.article .articleImage {
  width: 50px;
}


/*******************************************
  DirectOrder
*******************************************/

.directOrder {
  background-color: #FFFFFF;
  margin-left: -15px;
  padding-left: 15px;

}

.directOrder input[name=articleId] {
  width: 100px
}

.directOrder input[name=quantity] {
  width: 50px
}

.directOrder input[name=name] {
  width: 100px
}

.imgText {
  padding: 20px;
}

/*******************************************
  ProductList
*******************************************/

.productList {
  width: 350px;
}


/*******************************************
  Backoffice Main
*******************************************/

.backofficeContent {
  min-height: 100vh;
}

.mainPage {
  background-color: #f5f7f9;
  min-height: inherit;
}

.mainPage .row {
  min-height: inherit;
}

.messageLogout {
  font-size: 1.6rem;
  color: #ff0000;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  min-height: 10vh;
}


/*******************************************
  Backoffice Header
*******************************************/

.navbar-expand-lg .navbar-nav {
  flex-direction: row;
}

.header {
  width: 100%;
  width: -webkit-fill-available;
  width: -moz-available;
}

.header .infoBar {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 20px 50px;
}

.header .infoBar .login {
  position: relative;
  text-align: right;
  float: right;
}

.header .infoBar .login .navs {
  width: 140px;
  alignment: right;
  text-align: right;
  margin-right: 10px;
  display: flex;
}

.header .infoBar .icons .help {
  text-align: center;
  color: #15113b;
  font-family: Ubuntu, "Medium";
  font-size: 1.8rem;
  font-weight: bold;
  margin: 12px;
  padding: 6px;
}

.header .infoBar .icons .task {
  text-align: center;
  color: #15113b;
  font-family: Ubuntu, "Medium";
  font-size: 1.8rem;
  font-weight: bold;
  margin: 12px;
  padding: 6px;
}

.header .infoBar .icons .notification {
  text-align: center;
  color: #15113b;
  font-family: Ubuntu, "Medium";
  font-size: 1.8rem;
  font-weight: bold;
  margin: 12px;
  padding: 6px;
}

.header .infoBar .icons .verticalLine {
  padding-left: 10px;
}

.header .infoBar .login .navs .dropdown {
  text-align: right;
  float: right;
}

.header .infoBar .login .navs .entrySpinner {
  width: -webkit-fill-available;
  text-align: right;
  margin-right: 10px;
  float: right;
}

.header .logo {
  padding-top: 9px;
  padding-left: 30px;
}

.header .textHolder {
  padding-left: 25px;
}

.header .search {
  border-style: hidden;
  font-size: 1.5rem;
}

.header .search.form-control {
  height: 70px;
  padding-top: 6px;
  margin-top: 0px;
  border-top-style: hidden;
  border-top-width: 0px;
  width: 100%;
  alignment: left;
  float: left;
}

.icons {
  position: relative;
  display: flex;
  padding-left: 15px;
}

.collapseImage {
  margin-bottom: 9px;
}

.collapseIcon {
  background-color: #FFFFFF !important;
  border: 1px solid #FFFFFF !important;
  margin-left: 4px;
}

.collapse .link {
  padding: 1x;
}

/*******************************************
  Backoffice Cart
*******************************************/

.cellCartContent {
  width: 33%;
  float: left;
}


/*******************************************
  Backoffice Price
*******************************************/

.prices {
  display: flex;
  flex-direction: column;
}

input {
  border: 1px solid #E8E9EC;
}


/*******************************************
  Backoffice Currency - New Currency
*******************************************/

.newCurrency .tableContent .value {
  padding-bottom: 25px;
}

.tableContent .function-button.addNewCurrency {
  background-color: #00AEEF;
  color: #ffffff;
}


/*******************************************
  Backoffice Price - New Price
*******************************************/

.newPrice .tableContent .value {
  padding-bottom: 25px;
}

.tableContent .function-button.addNewPrice {
  background-color: #00AEEF;
  color: #ffffff;
}


/*******************************************
  Backoffice Price - Existing Price
*******************************************/

.existingPriceContainer {
  margin-bottom: 50px;
}

.articleIdNew,
.minQuantity,
.replenishmentTime {
  text-align: center;
  border: 1px solid #E8E9EC;
  border-radius: 0.25rem;
}

.minQuantity {
  width: inherit;
}

.unitPrice {
  text-align: center;
  width: inherit;
}

/*******************************************
  Backoffice Availability - Existing Availability
*******************************************/


.existingAvailabilityInput {
  height: 37px;
}

.existingAvailabilityStatus {
  width: fit-content;
}


/*******************************************
  Backoffice Availability - New Availability
*******************************************/

.availabilityContainer {
  width: 500px;
}

.newAvailabilityStatus {
  width: fit-content;
}

.newAvailability .tableContent .value {
  padding-bottom: 25px;
}


/*******************************************
  Backoffice Order - Orders
*******************************************/

.orders {
  display: flex;
  flex-direction: column;
}

.cart {
  display: flex;
  flex-direction: column;
}

.fade.show.popover.bs-popover-bottom {
  margin-left: 38px !important;
}

.modal-footer .closeView {
  font-size: 1.3rem;
}

/*******************************************
  Backoffice Components
*******************************************/

.pageName {
  font-family: "Montserrat", serif;
  font-size: 2.8rem;
  font-weight: 500;
  margin-top: 20px;
  margin-bottom: 20px;
  width: 100%;
}

.pageName .label {
  padding-left: 0;
  color: black;
}

.pageName .value {
  font-weight: 100;
  color: black;
}

.backofficeTable {
  margin-top: 20px;
  font-size: 1.5rem;
}

.backofficeTable .tab {
  padding-left: 0;
}

.backofficeTable .tab .button,
.backofficeTable .tab .all {
  width: 85px;
  height: 40px;
  font-size: 1.3rem;
  background-color: #F8F8F8;
  border: 1px solid #E9E9F0;
  border-bottom: none;
  opacity: 0.5;
  color: #43425D;
}

.backofficeTable .tab .button.active {
  background-color: #F5F7F9;
  color: #00AEEF;
  opacity: 1;
  border: 1px solid #E9E9F0;
}

.backofficeTable .features {
  display: flex;
  justify-content: flex-end;
  padding: 0;
}

.backofficeTable .features .filter,
.backofficeTable .features .addNew {
  width: 100px;
  height: 32px;
  font-size: 1.3rem;
  background-color: #FFFFFF;
  border: 1px solid #E9E9F0;
  opacity: 1;
  color: #4D4F5C;
  padding: 0;
}

.backofficeTable .features .filter {
  margin-right: 15px;
}

.backofficeTable .features .value {
  padding-left: 3px;
}

.mainContent.col-xl-10 {
  margin-right: 25px;
}

.tableHeader {
  font-family: "Ubuntu";
  color: #808495;
  font-size: 1.3rem;
  height: 45px;
}

.tableHeader .label {
  padding-top: 15px;
  background-color: #FFFFFF;
  text-align: center;
}

.tableContent .value {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  background-color: #FFFFFF;
  color: #4D4F5C;
  padding-top: 15px;
  font-size: 1.3rem;
  font-family: "Ubuntu";
  font-weight: 100;
}

.tableContent .function-button {
  display: flex;
  align-items: center;
  color: #4D4F5C;
  background-color: #FFFFFF;
  border-color: #E8E9EC;
  font-size: 1.3rem;
  font-family: "Ubuntu";
  font-weight: 100;
}

.tableContent .function-button:disabled {
  background-color: #E8E9EC;
}

.tableContent .function-button p {
  margin: 0;
  padding-left: 3px;
}

.row .divider {
  background-color: #F5F7F9;
  padding-top: 30px;
}

.content.modal-title {
  font-family: "Montserrat";
  color: #808495;
}

.additionalOrderInfo {
  display: flex;
  flex-direction: column;
  font-family: "Ubuntu";

}

.additionalOrderInfo .content {
  padding-top: 10px;
  color: #808495;
}

.additionalOrderInfo .values,
.content.modal-title .value {
  color: #4D4F5C;
  font-weight: 500;
}

.closeView {
  font-family: "Ubuntu";
}

.modal-orderInfo.modal-lg {
  max-width: 1200px;
}

.paging {
  font-family: "Ubuntu";
  font-size: 1.3rem;
}

.paging .content {
  padding-top: 30px;
  padding-bottom: 15px;
  background-color: #FFFFFF;
}

.paging .buttons.pagination {
  justify-content: flex-end;
}

.paging .page-item .page-link {
  color: #4D4F5C80;
  background-color: #FFFFFF;
  border: 1px solid #E8E9EC;
}

.paging .page-item.active .page-link {
  color: #4D4F5CFF;
  font-weight: 500;
}


/*******************************************
  Spinner
*******************************************/

.spinner {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  min-height: 100vh;
}

.spinner-header {
  color: #00AEEF;
  margin-right: 147.5px;
}


/*******************************************
  Error
*******************************************/

.error {
  color: #ff0000;
  font-size: 1.2rem;
  text-align: right;
}


/*******************************************
  Vertical Menu
*******************************************/

.verticalMenu {
  width: 253px; /* Set a width if you like */
  height: auto;
  border-right-color: rgb(206, 212, 218);
  border-right-style: solid;
  border-right-width: 1px;
  background-color: white;
  padding-right: 0;
}

.verticalMenu a {
  background-color: white; /* Grey background color */
  color: #43425D; /* Black text color */
  display: block; /* Make the links appear below each other */
  padding: 12px; /* Add some padding */
  text-decoration: none; /* Remove underline from links */
  font-family: Ubuntu, "Medium";
  font-size: 1.6rem;
}

.verticalMenu a:hover {
  background-color: #f5f7f9; /* Dark grey background on mouse-over */
}

.verticalMenu a.active {
  border-left-color: #00AEEF;
  border-left-style: solid;
  border-left-width: 5px;
  background-color: #f5f7f9; /* Add a blue color to the "active/current" link */
  color: #43425D;
  background-size: 10px 10px;
}

.verticalMenu a.disabled {
  color: #6c757d;
  pointer-events: none;
  cursor: default;
}


.verticalMenu .footer {
  font-family: Ubuntu, "Light";
  font-size: 1.4rem;
  position: absolute;
  bottom: 10px;
  text-align: center;
  display: block;
  margin-top: .25rem;
  text-align: center !important;
  padding-left: 35px;
  color: #4D4F5C;
}

.verticalMenu .footer .links .col-md-auto a {
  font-family: Ubuntu, "Light";
  font-size: 1.1rem;
  float: right;
  padding-top: 0.25em;
  color: #4D4F5C;
}

.verticalMenu .footer .links .col-md-auto.terms {
  font-size: 14px;
  float: right;
  padding-right: 0px;
}

.verticalMenu .footer .links .col-md-auto.terms .nav-link {
  padding-right: 5px;
}

.verticalMenu .footer .links .col-md-auto.privacy {
  font-size: 14px;
  float: right;
  padding-left: 0px;
}

.verticalMenu .footer .links .col-md-auto.privacy .nav-link {
  padding-left: 5px;
}

.verticalMenu .footer .links .col-md-auto.verticalLine {
  font-size: 11px;
  padding: 0px;
  margin: 0px;
}

.verticalMenu .footer .links .col-md-auto a:hover {
  background-color: white; /* Dark grey background on mouse-over */
  text-decoration: underline;
}

.verticalMenu .footer .links {
  padding-top: 0px;
}

.verticalMenu .link {
  padding-left: 15px;
  display: inline;
  font-size: 1.6rem;
}

.verticalMenu.collapsed {
  max-width: 4%;
}

.collapse.link {
  font-size: 1.6rem;
  color: #43425D;
  padding-left: 15px;
  padding-top: 2px;
}

.navbar-expand-lg {
  width: 100%;
  width: -webkit-fill-available;
  width: -moz-available;
  padding: 0px;
  padding-left: 42px;
  margin: 0px;
}


/*******************************************
  Body
*******************************************/

.mainViewBody {
  font-family: Montserrat;
}

.mainViewBody .heading {
  font-family: Montserrat;
  font-size: 2.8rem;
  font-weight: 500;
  margin-top: 20px;
  margin-bottom: 20px;
  padding-left: 8px;
}

.mainViewBody .heading .value {
  font-weight: 100;
}

.visually-hidden {
  display: none;
}

/*******************************************
  Message Components
*******************************************/

.messages {
  font-family: "Ubuntu", sans-serif;
  font-weight: 100;
}

.messages .content {
  align-items: center;
}

.messages .content .level {
  color: #43425D;
  opacity: 0.5;
  font-weight: 500;
}

.background {
  position: relative;
  top: 0;
  left: 0;
}

.level-image {
  position: absolute;
  top: 11px;
  left: 26px;
}

.Toastify__close-button {
  align-self: center !important;
  color: #A4AFB7 !important;
  opacity: 1 !important;
}